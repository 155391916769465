'use client';

import {
  PropsWithChildren,
  Suspense,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import useScreen from '@/hooks/useScreenMedia';
import { useGlobalStateStore } from '@/providers/globalState';
import { Html } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import Spline from '@splinetool/react-spline';
import { motion, useScroll } from 'framer-motion';

import useSectionObserver from '../../hooks/useActiveSection';
import { useLenisContext } from '../../providers/scroll/LenisWrapper';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import AvatarScene from './AvatarScene';
import HeroBackground from './HeroBackground';

const Spiral = () => {
  const { scrollYProgress } = useScroll();

  const spiral = useRef<any>(null);
  const camera = useRef<any>(null);

  function onLoad(spline: any) {
    const obj = spline.findObjectByName('spiral');
    const cameraObj = spline.findObjectByName('Camera');
    // save it in a ref for later use
    spiral.current = obj;
    camera.current = cameraObj;
    if (spiral.current) {
      spiral.current.rotation.z = 0;
    }
    if (camera.current?.position) {
      camera.current.position.y = -1000;
      camera.current.position.x = 0;
      camera.current.position.z = 0;
    }
  }
  const onChange = useCallback(
    (v: any) => {
      if (spiral.current) {
        spiral.current.rotation.z = 6 * v * Math.PI;
        spiral.current.scale.y = v * 4;
        spiral.current.scale.x = v * 4;
      }
      if (camera.current) {
        const value = -4000 + 3000 * v;
        camera.current.position.y = value;
        camera.current.position.x = 0;
        camera.current.position.z = 0;
      }
    },
    [spiral, camera]
  );

  scrollYProgress.on('change', onChange);
  return (
    <Spline
      className="pointer-events-none"
      scene="/liquid_spiral.spline"
      onLoad={onLoad}
      onPointerMove={() => {
        console.log('move');
      }}
    />
  );
};

const Hero = (props: PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  const { lenis } = useLenisContext();
  const loading = useGlobalStateStore((state: any) => state.loading);
  const isMobile = useScreen();

  const section = useSectionObserver(lenis, 100);
  const activeSection = loading ? 'preloader' : section;

  const scene = useMemo(() => <Spiral />, []);

  const sceneSize = isMobile
    ? {
        zIndex: -10,
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
      }
    : {
        zIndex: -10,
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 600,
      };
  return (
    <section
      className={`relative h-[300vh] w-full overflow-x-clip text-clip bg-transparent sm:h-[300vh]`}
      {...props}
    >
      {/* Background && Avatar */}
      <div
        className={
          'sticky left-0 top-0 -z-10 h-[120vh] w-[120vw] translate-x-[-10vw] translate-y-[-10vh]'
        }
      >
        <HeroBackground />
      </div>
      <motion.div className="fixed left-0 top-0 -z-10 size-full overflow-hidden">
        {scene}
      </motion.div>
      {/* bg-gradient-radial from-primary from-[1%] via-[#001501] to-transparent */}
      <motion.div
        animate={activeSection}
        className="!pointer-events-none fixed z-40 aspect-[2/3] w-[300px] md:w-[500px]"
        variants={{
          hero: {
            top: '50vh',
            left: '50vw',
            transform: 'translate(-50%, -50%)',
            width: '100vw',
            height: '100vh',
          },
          skills: {
            top: '50vh',
            left: '50vw',
            transform: 'translate(-50%, -50%)',
            width: '100vw',
            height: '100vh',
          },
          projects: {
            opacity: 0,
            ...sceneSize,
          },
          blog: {
            opacity: 0,
            ...sceneSize,
          },
          contact: {
            opacity: 0,
            ...sceneSize,
          },
        }}
      >
        <Canvas className="!pointer-events-none w-full" shadows>
          <Suspense
            fallback={
              <Html>
                <LoadingSpinner />
              </Html>
            }
          >
            <AvatarScene activeSection={activeSection} />
          </Suspense>
        </Canvas>
      </motion.div>
    </section>
  );
};

export default Hero;
