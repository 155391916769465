import React, { useEffect, useMemo, useState } from 'react';
import { facialExpressions } from '@/config/avatar';
import { ContactShadows, useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

let setupMode = false;

const Avatar = React.forwardRef(({ facialExpression }: any, ref) => {
  const model = useGLTF('/avatar/model.glb', true);
  const { nodes, materials, scene } = useMemo(() => model, [model]);

  // const [facialExpression, setFacialExpression] = useState('');

  // useControls('FacialExpressions', {
  //   facialExpression: {
  //     options: Object.keys(facialExpressions),
  //     onChange: (value) => setFacialExpression(value),
  //   },
  //   enableSetupMode: button(() => {
  //     setupMode = true;
  //   }),
  //   disableSetupMode: button(() => {
  //     setupMode = false;
  //   }),
  //   logMorphTargetValues: button(() => {
  //     const emotionValues = {};
  //     Object.keys(nodes.Eye_Mesh.morphTargetDictionary).forEach((key) => {
  //       if (key === 'eyeBlinkLeft' || key === 'eyeBlinkRight') {
  //         return; // eyes wink/blink are handled separately
  //       }
  //       const value =
  //         nodes.Eye_Mesh.morphTargetInfluences[nodes.Eye_Mesh.morphTargetDictionary[key]];
  //       if (value > 0.01) {
  //         emotionValues[key] = value;
  //       }
  //     });
  //     console.log(JSON.stringify(emotionValues, null, 2));
  //   }),
  // });

  // const [, set] = useControls('MorphTarget', () =>
  //   Object.assign(
  //     {},
  //     ...Object.keys(nodes.Eye_Mesh.morphTargetDictionary).map((key) => {
  //       return {
  //         [key]: {
  //           label: key,
  //           value: nodes.Eye_Mesh.morphTargetInfluences[nodes.Eye_Mesh.morphTargetDictionary[key]],
  //           min: -5,
  //           max: 5,
  //           onChange: (val) => {
  //             if (setupMode) {
  //               lerpMorphTarget(key, val, 1);
  //             }
  //           },
  //         },
  //       };
  //     }),
  //     ...Object.keys(nodes.Head_Mesh.morphTargetDictionary).map((key) => {
  //       return {
  //         [key]: {
  //           label: key,
  //           value:
  //             nodes.Head_Mesh.morphTargetInfluences[nodes.Head_Mesh.morphTargetDictionary[key]],
  //           min: -5,
  //           max: 5,
  //           onChange: (val) => {
  //             if (setupMode) {
  //               lerpMorphTarget(key, val, 1);
  //             }
  //           },
  //         },
  //       };
  //     })
  //   )
  // );

  const lerpMorphTarget = (target: any, value: number, speed = 0.1) => {
    scene.traverse((child: any) => {
      if (child.isSkinnedMesh && child.morphTargetDictionary) {
        const index = child.morphTargetDictionary[target];
        if (!index) {
          return;
        }
        child.morphTargetInfluences[index] = THREE.MathUtils.lerp(
          child.morphTargetInfluences[index],
          value,
          speed
        );
        // if (!setupMode) {
        //   try {
        //     set({
        //       [target]: value,
        //     });
        //   } catch (e) {}
        // }
      }
    });
  };

  const [blink, setBlink] = useState(false);

  useEffect(() => {
    let blinkTimeout: NodeJS.Timeout;
    const nextBlink = () => {
      blinkTimeout = setTimeout(
        () => {
          setBlink(true);
          setTimeout(() => {
            setBlink(false);
            nextBlink();
          }, 200);
        },
        THREE.MathUtils.randInt(1000, 5000)
      );
    };
    nextBlink();
    return () => clearTimeout(blinkTimeout);
  }, []);

  useFrame(() => {
    !setupMode &&
      [
        ...Object.keys((nodes.Eye_Mesh as any)?.morphTargetDictionary),
        ...Object.keys((nodes.Head_Mesh as any)?.morphTargetDictionary),
      ].forEach((key) => {
        if (key === 'eyeBlinkLeft' || key === 'eyeBlinkRight') {
          return;
        }
        const mapping = facialExpressions[facialExpression];
        if (mapping?.[key]) {
          lerpMorphTarget(key, mapping[key], 1);
        } else {
          lerpMorphTarget(key, 0, 1);
        }
      });

    lerpMorphTarget('eyeBlinkLeft', blink ? 1 : 0, 0.5);
    lerpMorphTarget('eyeBlinkRight', blink ? 1 : 0, 0.5);
  });
  return (
    <group ref={ref as React.MutableRefObject<THREE.Group>} dispose={null}>
      <ContactShadows opacity={0.5} blur={1} far={10} resolution={256} color="#000000" />
      <primitive object={nodes.Hips} />
      <skinnedMesh
        geometry={(nodes.avaturn_hair_0 as any)?.geometry}
        material={materials.avaturn_hair_0_material}
        skeleton={(nodes.avaturn_hair_0 as any)?.skeleton}
      />
      <skinnedMesh
        geometry={(nodes.avaturn_hair_1 as any)?.geometry}
        material={materials.avaturn_hair_1_material}
        skeleton={(nodes.avaturn_hair_1 as any)?.skeleton}
      />
      <skinnedMesh
        geometry={(nodes.avaturn_look_0 as any)?.geometry}
        material={materials.avaturn_look_0_material}
        skeleton={(nodes.avaturn_look_0 as any)?.skeleton}
      />
      <skinnedMesh
        geometry={(nodes.avaturn_shoes_0 as any)?.geometry}
        material={materials.avaturn_shoes_0_material}
        skeleton={(nodes.avaturn_shoes_0 as any)?.skeleton}
      />
      <skinnedMesh
        geometry={(nodes.Body_Mesh as any)?.geometry}
        material={materials.Body}
        skeleton={(nodes.Body_Mesh as any)?.skeleton}
      />
      <skinnedMesh
        name="Eye_Mesh"
        geometry={(nodes.Eye_Mesh as any)?.geometry}
        material={materials.Eyes}
        skeleton={(nodes.Eye_Mesh as any)?.skeleton}
        morphTargetDictionary={(nodes.Eye_Mesh as any)?.morphTargetDictionary}
        morphTargetInfluences={(nodes.Eye_Mesh as any)?.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeAO_Mesh"
        geometry={(nodes.EyeAO_Mesh as any)?.geometry}
        material={materials.EyeAO}
        skeleton={(nodes.EyeAO_Mesh as any)?.skeleton}
        morphTargetDictionary={(nodes.EyeAO_Mesh as any)?.morphTargetDictionary}
        morphTargetInfluences={(nodes.EyeAO_Mesh as any)?.morphTargetInfluences}
      />
      <skinnedMesh
        name="Eyelash_Mesh"
        geometry={(nodes.Eyelash_Mesh as any)?.geometry}
        material={materials.Eyelash}
        skeleton={(nodes.Eyelash_Mesh as any)?.skeleton}
        morphTargetDictionary={(nodes.Eyelash_Mesh as any)?.morphTargetDictionary}
        morphTargetInfluences={(nodes.Eyelash_Mesh as any)?.morphTargetInfluences}
      />
      <skinnedMesh
        name="Head_Mesh"
        geometry={(nodes.Head_Mesh as any)?.geometry}
        material={materials.Head}
        skeleton={(nodes.Head_Mesh as any)?.skeleton}
        morphTargetDictionary={(nodes.Head_Mesh as any)?.morphTargetDictionary}
        morphTargetInfluences={(nodes.Head_Mesh as any)?.morphTargetInfluences}
      />
      <skinnedMesh
        name="Teeth_Mesh"
        geometry={(nodes.Teeth_Mesh as any)?.geometry}
        material={materials.Teeth}
        skeleton={(nodes.Teeth_Mesh as any)?.skeleton}
        morphTargetDictionary={(nodes.Teeth_Mesh as any)?.morphTargetDictionary}
        morphTargetInfluences={(nodes.Teeth_Mesh as any)?.morphTargetInfluences}
      />
      <skinnedMesh
        name="Tongue_Mesh"
        geometry={(nodes.Tongue_Mesh as any)?.geometry}
        material={materials['Teeth.001']}
        skeleton={(nodes.Tongue_Mesh as any)?.skeleton}
        morphTargetDictionary={(nodes.Tongue_Mesh as any)?.morphTargetDictionary}
        morphTargetInfluences={(nodes.Tongue_Mesh as any)?.morphTargetInfluences}
      />
    </group>
  );
});

Avatar.displayName = 'Avatar';
useGLTF.preload('/avatar/model.glb');

export default Avatar;
