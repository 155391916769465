'use client';

import React, { Suspense, useEffect } from 'react';
import { useGlobalStateStore } from '@/providers/globalState';
import { useShallow } from 'zustand/react/shallow';
import { Canvas } from '@react-three/fiber';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { motion as motion3d } from 'framer-motion-3d';
import Bishop from './Bishop';
import { Environment, Html } from '@react-three/drei';

export default function PreLoader() {
  const { loading, setLoading } = useGlobalStateStore(
    useShallow((state: any) => ({
      loading: state.loading,
      setLoading: state.setLoading,
    }))
  );

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, [setLoading]);

  return (
    <section
      className={
        'fixed left-0 top-0 z-[110] h-screen w-screen bg-primary ' + (loading ? '' : '!hidden')
      }
    >
      <Canvas className="!pointer-events-none w-full" shadows>
        <Suspense
          fallback={
            <Html>
              <LoadingSpinner />
            </Html>
          }
        >
          {/* Rotate the bishop */}
          {loading && (
            <motion3d.mesh
              initial={{ rotateX: 0, rotateY: 0 }}
              animate={{
                rotateY: [0, Math.PI * 4, 0], // Rotate 360 degrees on Y-axis
              }}
              transition={{
                duration: 20, // Total animation duration
                times: [0, 0.5, 1], // Keyframe timing
                repeat: Infinity, // Loop the animation
                ease: 'linear', // Smooth animation
              }}
            >
              <Bishop scale={0.4} />
            </motion3d.mesh>
          )}
          <Environment preset="sunset" />
        </Suspense>
      </Canvas>
    </section>
  );
}
