import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export function WebIllustrator(props: any) {
  const { nodes, materials } = useGLTF('/skills/website-illustrator.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <group position={[0.505, -0.402, -1.488]} rotation={[0.871, -0.391, 0.425]} scale={1.591}>
        <mesh geometry={(nodes.Cube002_1 as any).geometry} material={materials['Material.007']} />
        <mesh geometry={(nodes.Cube002_2 as any).geometry} material={materials['in mouse']} />
      </group>
      <group position={[0.419, 0.022, 1.471]} rotation={[-2.289, -0.35, 0.374]} scale={1.591}>
        <mesh geometry={(nodes.Cube003 as any).geometry} material={materials['Mouse 02']} />
        <mesh geometry={(nodes.Cube003_1 as any).geometry} material={materials['in Mouse 02']} />
      </group>
      <group position={[0.314, -0.833, 1.593]} rotation={[-0.858, 0.364, -2.751]} scale={-1.591}>
        <mesh geometry={(nodes.Cube005 as any).geometry} material={materials['Mouse 03']} />
        <mesh geometry={(nodes.Cube005_1 as any).geometry} material={materials['in Mouse']} />
      </group>
      <mesh
        geometry={(nodes.Text as any).geometry}
        material={materials['Material.001']}
        position={[0.171, 1.037, -1.558]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.087}
      />
      <mesh
        geometry={(nodes.text002 as any).geometry}
        material={materials['Material.001']}
        position={[0.105, 1.285, 1.457]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.124}
      />
      <mesh
        geometry={(nodes.wirframe as any).geometry}
        material={materials.Desktop}
        position={[0.194, -0.165, 0]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={1.08}
      />
      <mesh
        geometry={(nodes.wirframe001 as any).geometry}
        material={materials.Desktop}
        position={[0.201, -0.165, 0]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={1.08}
      />
      <mesh
        geometry={(nodes.Arrow as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, 1.567]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={6.671}
      />
      <mesh
        geometry={(nodes.Type as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, 0.531]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={6.065}
      />
      <mesh
        geometry={(nodes.Square as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, 1.056]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.066}
      />
      <mesh
        geometry={(nodes.Play as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, -1.805]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={6.065}
      />
      <mesh
        geometry={(nodes['Pen-tools'] as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, 0.783]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={6.065}
      />
      <mesh
        geometry={(nodes.Menu as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, 1.827]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={6.065}
      />
      <mesh
        geometry={(nodes.Hash as any).geometry}
        material={materials.Desktop}
        position={[0.14, 1.05, 1.332]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={6.065}
      />
      <group position={[0.288, 0.148, -1.541]} scale={0.122}>
        <mesh geometry={(nodes.Cube as any).geometry} material={materials.Button} />
        <mesh geometry={(nodes.Cube_1 as any).geometry} material={materials.Material} />
      </group>
      <group position={[0.288, -0.273, -1.541]} scale={0.122}>
        <mesh geometry={(nodes.Cube004 as any).geometry} material={materials.Desktop} />
        <mesh geometry={(nodes.Cube004_1 as any).geometry} material={materials.gray} />
      </group>
      <group position={[0.385, 0.148, -0.555]} scale={0.149}>
        <mesh geometry={(nodes.Cube006 as any).geometry} material={materials.Button} />
        <mesh geometry={(nodes.Cube006_1 as any).geometry} material={materials['Material.006']} />
      </group>
      <mesh
        geometry={(nodes.Search as any).geometry}
        material={materials.gray}
        position={[0.39, 0.124, -0.14]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={7.396}
      />
      <mesh
        geometry={(nodes.Text001 as any).geometry}
        material={materials.text_black}
        position={[0.4, 0.117, -0.461]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.09}
      />
      <mesh
        geometry={(nodes.Text002 as any).geometry}
        material={materials.text_black}
        position={[0.4, -0.304, -0.345]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.086}
      />
      <group position={[0.413, 0.52, -0.857]} scale={0.122}>
        <mesh geometry={(nodes.Cube007 as any).geometry} material={materials.Button} />
        <mesh geometry={(nodes.Cube007_1 as any).geometry} material={materials['Material.003']} />
      </group>
      <mesh
        geometry={(nodes.Text003 as any).geometry}
        material={materials.text_black}
        position={[0.431, 0.505, -0.184]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.065}
      />
      <mesh
        geometry={(nodes.Text004 as any).geometry}
        material={materials['Material.001']}
        position={[0.431, 0.505, -0.775]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.066}
      />
      <mesh
        geometry={(nodes.Text005 as any).geometry}
        material={materials.text_black}
        position={[0.431, 0.505, -1.421]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.07}
      />
      <mesh
        geometry={(nodes.Card as any).geometry}
        material={materials.Desktop}
        position={[0.311, 0.107, -0.81]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.637}
      />
      <mesh
        geometry={(nodes.Plane as any).geometry}
        material={materials.Selected}
        position={[0.427, -0.272, -1.49]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.124}
      />
      <mesh
        geometry={(nodes.Card001 as any).geometry}
        material={materials.Desktop}
        position={[0.245, -0.16, 1.064]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.637}
      />
      <mesh
        geometry={(nodes.Button001 as any).geometry}
        material={materials.Button}
        position={[0.313, -0.614, 1.037]}
        scale={0.122}
      />
      <mesh
        geometry={(nodes.Text006 as any).geometry}
        material={materials.text_black}
        position={[0.331, -0.617, 1.039]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.06}
      />
      <group
        position={[0.262, -0.124, 1.064]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.452}
      >
        <mesh geometry={(nodes.Plane009 as any).geometry} material={materials['in mouse']} />
        <mesh geometry={(nodes.Plane009_1 as any).geometry} material={materials.Button} />
      </group>
      <mesh
        geometry={(nodes.Select as any).geometry}
        material={materials.Selected}
        position={[0.314, -0.257, 1.064]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.124}
      />
      <group position={[0.334, 0.703, 0.484]} rotation={[1.246, 0, -Math.PI / 2]} scale={6.905}>
        <mesh geometry={(nodes.Curve004 as any).geometry} material={materials['Material.002']} />
        <mesh geometry={(nodes.Curve004_1 as any).geometry} material={materials.starbg} />
      </group>
      <group position={[0.393, -0.581, -0.102]} rotation={[1.411, -0.011, -1.505]} scale={7.553}>
        <mesh geometry={(nodes.Curve008 as any).geometry} material={materials['Material.004']} />
        <mesh geometry={(nodes.Curve008_1 as any).geometry} material={materials.starbg} />
      </group>
      <group position={[0.476, 0.047, -0.971]} rotation={[1.885, 0, -Math.PI / 2]} scale={8.353}>
        <mesh geometry={(nodes.Curve011 as any).geometry} material={materials.starbg} />
        <mesh geometry={(nodes.Curve011_1 as any).geometry} material={materials.love} />
      </group>
      <mesh geometry={(nodes.Cube001_1 as any).geometry} material={materials['Body Main']} />
      <mesh geometry={(nodes.Cube001_2 as any).geometry} material={materials.Exit} />
      <mesh geometry={(nodes.Cube001_3 as any).geometry} material={materials.Resize} />
      <mesh geometry={(nodes.Cube001_4 as any).geometry} material={materials.Minimize} />
      <mesh geometry={(nodes.Cube001_5 as any).geometry} material={materials.Screen} />
      <mesh geometry={(nodes.Cube001_6 as any).geometry} material={materials['Menu Bar']} />
      <mesh geometry={(nodes.Cube001_7 as any).geometry} material={materials['Material.005']} />
    </motion.group>
  );
}

useGLTF.preload('/skills/website-illustrator.glb');
