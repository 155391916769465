import Image from 'next/image';
import { glassMorphismClass } from '@/config/constants';
import { motion } from 'framer-motion';

type IPinnedProject = {
  name: string;
  techStack: Array<string>;
  images: Array<string>;
  className: string;
  challenge: string;
  imageScale: number;
};
export default function PinnedProject({
  challenge,
  name,
  techStack,
  images,
  className,
}: Readonly<IPinnedProject>) {
  const [thumbnailImage, ..._] = images;
  return (
    <div className={className + ' hidden w-full grow flex-col items-start gap-4 md:!flex'}>
      <div className={`aspect-video size-full max-h-[300px]`}>
        <motion.div className={'relative size-full will-change-transform'}>
          <Image
            className="object-cover"
            src={thumbnailImage}
            alt={name}
            fill={true}
            sizes="(max-width: 768px) 700px"
            loading="lazy"
          />
        </motion.div>

        <div className={`absolute bottom-8 left-4 flex gap-2`}>
          {techStack.map((tech) => (
            <strong
              key={tech}
              className={`text-secondary-content flex items-center justify-center rounded-full px-3 py-1 text-sm font-extralight ${glassMorphismClass} dark:bg-black/30`}
            >
              {tech}
            </strong>
          ))}
        </div>
      </div>
      <div className="w-full">
        <h3 className="text-primary-content mb-6 text-xl lg:text-3xl">{name}</h3>
        <p className="text-secondary-content text-base font-thin">{challenge}</p>
      </div>
    </div>
  );
}
