export const defaultStyle: { [key: string]: number } = {
  scale: 4,
  rotateX: Math.PI / 36,
  rotateY: 0,
  rotateZ: 0,
  y: -6,
};

export const facialExpressions: {
  [key: string]: { [key: string]: number };
} = {
  default: {},
  smile: {
    eyeSquintLeft: 0.2,
    eyeSquintRight: 0.2,
    cheekSquintLeft: 0.3,
    cheekSquintRight: 0.3,
    mouthSmileLeft: 0.6,
    mouthSmileRight: 0.6,
    browInnerUp: 0.1,
  },
  freshSmile: {
    browInnerUp: 0.12,
    browOuterUpLeft: 0.15,
    browOuterUpRight: 0.15,
    eyeSquintLeft: 0.25,
    eyeSquintRight: 0.25,
    cheekSquintLeft: 0.5,
    cheekSquintRight: 0.5,
    mouthSmileLeft: 0.85,
    mouthSmileRight: 0.85,
    mouthStretchLeft: 0.3,
    mouthStretchRight: 0.3,
    jawOpen: 0.35,
    jawForward: 0.1,
    noseSneerLeft: 0.05,
    noseSneerRight: 0.05,
  },
  funny: {
    jawLeft: 0.63,
    mouthPucker: 0.53,
    noseSneerLeft: 1,
    noseSneerRight: 0.39,
    mouthLeft: 1,
    eyeLookUpLeft: 1,
    eyeLookUpRight: 1,
    cheekPuff: 0.9999924982764238,
    mouthDimpleLeft: 0.414743888682652,
    mouthRollLower: 0.32,
    mouthSmileLeft: 0.35499733688813034,
    mouthSmileRight: 0.35499733688813034,
  },
  surprise: {
    browInnerUp: 0.4,
    browOuterUpLeft: 0.45,
    browOuterUpRight: 0.45,
    eyeWideLeft: 0.5,
    eyeWideRight: 0.5,
    cheekSquintLeft: 0.1,
    cheekSquintRight: 0.1,
    mouthOpen: 0.4,
    jawOpen: 0.3,
    jawForward: 0.1,
  },
};

export const corresponding: {
  [key: string]: string;
} = {
  A: 'viseme_PP',
  B: 'viseme_kk',
  C: 'viseme_I',
  D: 'viseme_AA',
  E: 'viseme_O',
  F: 'viseme_U',
  G: 'viseme_FF',
  H: 'viseme_TH',
  X: 'viseme_PP',
};
