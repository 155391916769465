/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 public/book.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';

export default function Model(props: any) {
  const { nodes, materials } = useGLTF('/book.glb');
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 0]} scale={0.01}>
        <mesh
          geometry={(nodes['book-002'] as any)?.geometry}
          material={materials['Material.002']}
          position={[0, 0, -90.309]}
          rotation={[-0.798, 0.011, 3.086]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/book.glb');
