import { useEffect, useMemo, useRef } from 'react';
import { defaultStyle } from '@/config/avatar';
import { useGlobalStateStore } from '@/providers/globalState';
import { Environment, useAnimations, useFBX, useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

import Avatar from './Avatar';
import Book from './Book';
import AIScene from './skills/AIScene';
import BackendScene from './skills/BackendScene';
import FrontendScene from './skills/FrontendScene';

const IDLE_ANIMATION_KEY = 'idle';
const animationKeyMap: any = {
  hero: IDLE_ANIMATION_KEY,
  skills: IDLE_ANIMATION_KEY,
  projects: IDLE_ANIMATION_KEY,
  blog: 'sitting',
  contact: 'sitting',
};

const AvatarScene = ({ activeSection }: any) => {
  const currentSkill = useGlobalStateStore((state: any) => state.currentSkill);
  const rootRef = useRef<any>(null);
  const { animations: idle } = useFBX('/avatar/standing.fbx');
  const { animations: sitting } = useFBX('/avatar/sitting.fbx');

  idle[0].name = IDLE_ANIMATION_KEY;
  sitting[0].name = 'sitting';
  const memoizedAnimations = useMemo(
    () => [idle[0], sitting[0]],
    [idle, sitting]
  );
  const animation = useAnimations(memoizedAnimations, rootRef);
  const { actions } = useMemo(() => animation, [animation]);

  useEffect(() => {
    actions[IDLE_ANIMATION_KEY]?.fadeIn(0.5).play();
    return () => {
      actions[IDLE_ANIMATION_KEY]?.fadeOut(0.5);
    };
  }, [actions]);

  useEffect(() => {
    const animationKey = animationKeyMap[activeSection];
    if (animationKey !== IDLE_ANIMATION_KEY) {
      actions[animationKey]?.play();
      actions[IDLE_ANIMATION_KEY]?.stop();
    }
    return () => {
      if (animationKey !== IDLE_ANIMATION_KEY) {
        actions[animationKey]?.stop();
        actions[IDLE_ANIMATION_KEY]?.play();
      }
    };
  }, [actions, activeSection]);

  const sectionKey =
    activeSection === 'skills' ? `${activeSection}-${currentSkill}` : activeSection;
  return (
    <>
      <group>
        <Environment preset="sunset" environmentRotation={[0, -Math.PI / 15, 0]} />
        {sectionKey === 'blog' && (
          <Book
            position={[-0.25, -1.8, 1.7]}
            rotation={[Math.PI / 2 - 0.12, -0.02, 0.7]}
            scale={2.2}
          />
        )}
        {sectionKey === 'skills-0' && <BackendScene />}
        {sectionKey === 'skills-1' && <FrontendScene />}
        {sectionKey === 'skills-2' && <AIScene />}
        <motion.group
          animate={sectionKey}
          transition={{
            duration: 0.4,
          }}
          position={[0, -6, 0]}
          scale={4}
          variants={{
            hero: {
              ...defaultStyle,
              y: -6,
            },
            'skills-0': { ...defaultStyle, y: -2, scale: 2.4, rotateY: -Math.PI / 2 },
            'skills-1': { ...defaultStyle, y: -2, scale: 2.4, rotateY: (-Math.PI * 3) / 2 },
            'skills-2': { ...defaultStyle, y: -2, scale: 2.4, rotateY: -Math.PI * 2 },
            projects: {
              ...defaultStyle,
              scale: 2.4,
              y: -2.5,
              x: 0,
              rotateY: -Math.PI * 2 - Math.PI / 3,
              rotateX: 0.3,
              rotateZ: 0.1,
            },
            blog: {
              ...defaultStyle,
              rotateX: 0,
              rotateY: -Math.PI * 2 - Math.PI / 4,
              scale: 3,
              y: -1.5,
              x: 1,
            },
            contact: {
              ...defaultStyle,
              rotateX: 0,
              rotateY: -Math.PI * 2 - Math.PI / 4,
              scale: 3,
              y: -1.5,
              x: 1,
            },
          }}
        >
          <Avatar
            facialExpression={activeSection === 'skills' ? 'surprise' : 'smile'}
            ref={rootRef}
          />
        </motion.group>
      </group>
    </>
  );
};

useGLTF.preload('/avatar/model.glb');

export default AvatarScene;
