import { useState } from 'react';
import { motion, useTransform } from 'framer-motion';

import PinnedProject from './PinnedProject';
import ProjectLineItem from './ProjectLineItem';

type ICategorySection = {
  name: string;
  description: string;
  index: number;
  projects: Array<any>;
  progress: any;
  range: Array<number>;
  targetScale: number;
};

export default function CategorySection({
  name,
  description,
  index,
  projects,
  progress,
  range,
  targetScale,
}: ICategorySection) {
  const [currentProject, setCurrentProject] = useState(0);
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div className="sticky top-[80px] h-[560px] sm:h-[500px] md:h-[600px] lg:h-[720px]">
      <motion.div
        style={{
          scale,
          top: `${index * 25}px`,
        }}
        className="relative h-fit w-full origin-top rounded-2xl bg-slate-100 p-6 shadow-2xl will-change-[transform,top] md:py-6 lg:p-10 dark:bg-gray-800"
      >
        <div className="relative w-fit min-w-[160px]">
          <h2 className="text-primary-content absolute bottom-0 left-0 inline-block text-center text-4xl font-bold">
            {name}
          </h2>

          <span className="text-primary-content text-8xl !leading-[0.6] font-extrabold opacity-30 md:text-9xl">
            0{index + 1}
          </span>
        </div>
        <p className="text-secondary-content mt-4 mb-6 w-full text-lg font-thin">{description}</p>
        {/* // Projects */}
        <div className="flex gap-8">
          {/* Project Lists */}
          <div className="md:basis-2/5">
            <div className="flex flex-col gap-4">
              <h3 className="text-primary-content text-3xl uppercase">
                {projects.length} projects
              </h3>

              <ul>
                {projects.map(
                  (
                    {
                      name,
                      url,
                    }: {
                      name: string;
                      url: string;
                    },
                    index: number
                  ) => (
                    <ProjectLineItem
                      onMouseEnter={() => setCurrentProject(index)}
                      key={name}
                      index={index}
                      name={name}
                      url={url}
                    />
                  )
                )}
              </ul>
            </div>
          </div>
          <PinnedProject {...(projects[currentProject] || projects[0])} className="md:basis-3/5" />
        </div>
      </motion.div>
    </div>
  );
}
