'use client';

import React, { HTMLAttributes, MutableRefObject, useRef } from 'react';
import { motion, useScroll } from 'framer-motion';
import { useShallow } from 'zustand/react/shallow';

import { useGlobalStateStore } from '../../providers/globalState';

const skills = {
  backend: {
    description: '',
    projects: [],
    model: '/backend.obj',
    material: '/backend.mtl',
  },
  frontend: {
    description: '',
    projects: [],
    model: '/frontend.obj',
    material: '/frontend.mtl',
  },
  ai: {
    description: '',
    projects: [],
    model: '/ai.obj',
    material: '/ai.mtl',
  },
};

const Skills = ({ categories, ...rest }: { categories: any[] } & HTMLAttributes<HTMLElement>) => {
  const targetRef = useRef<HTMLElement>(null) as MutableRefObject<HTMLElement>;
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const { currentSkill, setCurrentSkill } = useGlobalStateStore(
    useShallow((state) => ({
      setCurrentSkill: (state as any).setCurrentSkill,
      currentSkill: (state as any).currentSkill,
    }))
  );

  const skillLists = Object.keys(skills);

  scrollYProgress.on('change', (value) =>
    setCurrentSkill(Math.floor((value - 0.01) * skillLists.length))
  );

  const skillsMenu = (
    <ul className="flex w-fit flex-col items-end gap-8">
      {skillLists.map((skill, skillIndex) => {
        const id = `${skillIndex}`;
        return (
          <li key={skillIndex}>
            <input
              className="peer hidden"
              type="radio"
              name="skill"
              id={id}
              value={skillIndex}
              checked={skillIndex === (currentSkill ?? 0)}
              readOnly
            />
            <motion.label
              transition={{
                type: 'spring',
                duration: 0.25,
              }}
              htmlFor={id}
              className={
                'text-primary-content-100 dark:text-secondary-content-100 relative w-fit text-right text-xl font-semibold uppercase md:text-3xl' +
                ' after:absolute after:-bottom-[4px] after:right-0 after:origin-right after:bg-primary-content after:dark:bg-secondary-content' +
                ' after:block after:h-[3.5px] after:w-0 after:duration-500 after:content-[""]' +
                ' peer-checked:text-2xl peer-checked:font-bold peer-checked:text-primary-content peer-checked:after:w-full peer-checked:dark:text-secondary-content peer-checked:md:text-4xl'
              }
            >
              {skill}
            </motion.label>
          </li>
        );
      })}
    </ul>
  );
  return (
    <section
      className="container relative h-[900vh] text-clip bg-transparent"
      ref={targetRef}
      {...rest}
    >
      <div className="sticky top-0 z-50 h-screen overflow-hidden">
        <div className="absolute right-0 top-1/2 z-0 flex w-full -translate-y-1/2 flex-row items-center justify-end">
          {skillsMenu}
        </div>
      </div>
    </section>
  );
};

export default Skills;
