/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 ./public/skills/react.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export function ReactIcon(props: any) {
  const { nodes, materials } = useGLTF('/skills/react.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh geometry={(nodes.back as any).geometry} material={materials.back} />
        <mesh
          geometry={(nodes.LOGO as any).geometry}
          material={materials.logo}
          position={[-0.003, 0.648, 0.006]}
        />
      </group>
    </motion.group>
  );
}

useGLTF.preload('/skills/react.glb');
