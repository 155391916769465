import { useEffect, useState } from 'react';

export default function useScreen() {
  const [isMobile, setIsMobile] = useState(
    typeof window === 'undefined' ? true : window?.innerWidth < 768
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
  };
}
