import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export function Tailwind(props: any) {
  const { nodes, materials } = useGLTF('/skills/tailwind.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh geometry={(nodes.back as any).geometry} material={materials['back.001']} />
        <mesh
          geometry={(nodes.LOGO as any).geometry}
          material={materials['logo.001']}
          position={[-0.003, 0.657, 0.009]}
        />
      </group>
    </motion.group>
  );
}

useGLTF.preload('/skills/tailwind.glb');
