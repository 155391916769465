'use client';

import { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';

const HeroBackground = ({ className }: { className?: string }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  // Update cursor position on mouse move
  const handleMouseMove = (event: MouseEvent) => {
    setCursorPosition({ x: event.clientX, y: event.clientY });
  };

  // Update window size for calculating relative position
  const handleResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate the perspective movement
  const calculateTransform = () => {
    const offsetX = (cursorPosition.x / windowSize.width - 0.5) * 2; // Normalize to range [-1, 1]
    const offsetY = (cursorPosition.y / windowSize.height - 0.5) * 2; // Normalize to range [-1, 1]
    const translateX = offsetX * 5; // Adjust 5 for intensity
    const translateY = offsetY * -5; // Adjust 5 for intensity
    return `rotateX(${translateY}deg) rotateY(${translateX}deg)`;
  };

  const layers = [
    { src: '/swing/swing_00_veryback3.png', offset: 5 },
    { src: '/swing/swing_02_trees3.png', offset: 4 },
    { src: '/swing/swing_03_statue2.png', offset: 3 },
    { src: '/swing/swing_05_perv.png', offset: 2 },
    { src: '/swing/swing_06_fronttree.png', offset: 1 },
  ];

  const transform = calculateTransform();

  const calculateParallax = (zValue: number) => {
    const offsetX = (cursorPosition.x / windowSize.width - 0.5) * 2;
    const offsetY = (cursorPosition.y / windowSize.height - 0.5) * 2;

    // Multiply the parallax factor by different values for each layer to get varying depths
    const parallaxX = offsetX * zValue;
    const parallaxY = (offsetY + 3) * zValue;

    return `translateX(${parallaxX}px) translateY(${parallaxY}px)`;
  };

  const layersComponent = useMemo(
    () =>
      layers.map(({ src, offset }, index) => {
        const zPos = `translateZ(${-50 * offset}px)`;
        return (
          <motion.div
            className={`absolute flex size-full items-center justify-center will-change-transform`}
            key={index}
            style={{
              transform: `${zPos} ${calculateParallax(index * 10)}`,
            }}
          >
            <Image src={src} alt={`Layer ${index + 1}`} className="object-cover" fill={true} />
          </motion.div>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layers]
  );

  return (
    <motion.div
      className={`relative left-0 top-0 size-full overflow-hidden bg-black`}
      style={{
        perspective: 1000, // Adds perspective
        transformStyle: 'preserve-3d', // Maintain 3D effect
        transform, // Apply camera-like shift
      }}
    >
      {layersComponent}
    </motion.div>
  );
};

export default HeroBackground;
