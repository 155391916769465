import { ReactIcon } from './illustrators/ReactIcon';
import { Tailwind } from './illustrators/Tailwind';
import { WebIllustrator } from './illustrators/WebIllustrator';

export default function FrontendScene(props: any) {
  return (
    <>
      <WebIllustrator
        position={[-4, 0, -2]}
        transition={{
          duration: 1,
        }}
        animate={{ rotateY: [-Math.PI * 2, -Math.PI / 6], x: [4, -4] }}
      />
      <Tailwind
        position={[2, 0, -2]}
        scale={8}
        animate={{
          y: [2, 0],
          rotateX: [0, -2 * Math.PI],
          rotateY: [0, -2 * Math.PI],
        }}
        transition={{
          type: 'spring',
          duration: 2,
        }}
      />
      <ReactIcon
        position={[3, 0, 0]}
        scale={8}
        animate={{
          y: [0, 2],
          rotateX: [0, 1.5 * Math.PI],
          rotateY: [0, 1.5 * Math.PI],
        }}
        transition={{
          type: 'spring',
          dampling: 300,
          duration: 2,
        }}
      />
    </>
  );
}
