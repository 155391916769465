/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 ./public/skills/lambda.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export function Lambda(props: any) {
  const { nodes, materials } = useGLTF('/skills/lambda.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <mesh
        geometry={(nodes['AWS-Lambda_Icon_64_Squid'] as any).geometry}
        material={materials['SVGMat.001']}
        position={[-0.009, 0.001, 0.009]}
        scale={[0.807, 1.038, 0.807]}
      />
      <mesh
        geometry={(nodes.Cube as any).geometry}
        material={materials['Material.001']}
        position={[0, -0.002, 0]}
        scale={[0.01, 0.002, 0.01]}
      />
    </motion.group>
  );
}

useGLTF.preload('/skills/lambda.glb');
