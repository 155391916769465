import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export function CardPayment(props: any) {
  const { nodes, materials } = useGLTF('/skills/card-payment.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <mesh
        geometry={(nodes.Cube003 as any).geometry}
        material={materials['Material.008']}
        position={[-0.574, 1.181, 0.73]}
        rotation={[-0.206, -0.287, 0]}
        scale={[0.022, 0.094, 0.529]}
      >
        <mesh
          geometry={(nodes.Cube001 as any).geometry}
          material={materials['Material.006']}
          position={[0, 10.652, 0.488]}
          scale={[1, 1.896, 0.547]}
        />
        <mesh
          geometry={(nodes.Cube002 as any).geometry}
          material={materials['Material.007']}
          position={[0, 4.253, 0.488]}
          scale={[1, 1, 0.483]}
        />
        <mesh
          geometry={(nodes.Cube004 as any).geometry}
          material={materials['Material.002']}
          position={[-3.851, 6.251, -1.241]}
          scale={[4.165, 9.836, 2.839]}
        />
      </mesh>
      <mesh
        geometry={(nodes.Cube005 as any).geometry}
        material={materials['Material.004']}
        position={[-1.76, 1.917, -1.595]}
        rotation={[0.086, 0.359, 0.151]}
        scale={[0.094, 0.922, 1.502]}
      >
        <mesh
          geometry={(nodes.Cube006 as any).geometry}
          material={materials.Material}
          position={[0.852, 0.425, 0.647]}
          scale={[0.24, 0.202, 0.352]}
        />
      </mesh>
      <mesh
        geometry={(nodes.Cylinder001 as any).geometry}
        material={materials['Material.001']}
        position={[-0.618, 1.511, 1.97]}
        rotation={[-1.959, -0.537, -2.245]}
        scale={[0.46, 0.066, 0.46]}
      />
      <mesh
        geometry={(nodes.Cylinder002 as any).geometry}
        material={materials['Material.001']}
        position={[-2.758, 3.322, 0.912]}
        rotation={[-1.512, -0.65, -1.474]}
        scale={[0.46, 0.066, 0.46]}
      />
      <mesh
        geometry={(nodes.Cylinder003 as any).geometry}
        material={materials['Material.001']}
        position={[-0.648, 0.654, -2.791]}
        rotation={[-1.304, -0.602, -1.121]}
        scale={[0.46, 0.066, 0.46]}
      />
      <mesh
        geometry={(nodes.Cylinder004 as any).geometry}
        material={materials['Material.001']}
        position={[-1.943, 3.014, -2.592]}
        rotation={[-1.512, -0.65, -1.474]}
        scale={[0.46, 0.066, 0.46]}
      />
      <mesh
        geometry={(nodes.Cylinder005 as any).geometry}
        material={materials['Material.001']}
        position={[0.704, 0.798, -0.897]}
        rotation={[-1.845, -0.599, -2.034]}
        scale={[0.46, 0.066, 0.46]}
      />
    </motion.group>
  );
}

useGLTF.preload('/skills/card-payment.glb');
