import InlineLink from '../links/InlineLink/InlineLink';

const ProjectLineItem = ({ index, name, url, ...rest }: any) => {
  return (
    <li
      key={index}
      className="flex w-fit max-w-[360px] cursor-pointer items-center py-2 text-lg uppercase text-secondary-content"
      {...rest}
    >
      <InlineLink href={url} target="_blank" rel="noopener noreferrer">
        <strong>{name}</strong>
      </InlineLink>
    </li>
  );
};

export default ProjectLineItem;
