import { useEffect, useState } from 'react';

export default function useSectionObserver(lenis: any, threshold = 100) {
  const [activeSection, setActiveSection] = useState<string>('hero');
  useEffect(() => {
    const sections = Array.from(document.querySelectorAll('section'));
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;
      for (const section of sections) {
        const rect = section.getBoundingClientRect();
        const sectionTop = rect.top;
        const sectionBottom = rect.bottom;

        if (viewportHeight * 0.8 >= sectionTop && threshold <= sectionBottom) {
          setActiveSection(section.id);
        }
      }
    };
    lenis?.on('scroll', handleScroll);
  }, [lenis, threshold]);
  return activeSection;
}
