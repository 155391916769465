/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 ./public/skills/bedrock.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export default function Bedrock(props: any) {
  const { nodes, materials } = useGLTF('/skills/bedrock.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <mesh
        geometry={(nodes.Curve as any)?.geometry}
        material={materials['Material.001']}
        position={[-0.015, 0.003, 0.015]}
        scale={[100, 18.858, 100]}
      />
      <mesh
        geometry={(nodes.Cube as any)?.geometry}
        material={materials.SVGMat}
        scale={[0.02, 0.002, 0.02]}
      />
    </motion.group>
  );
}

useGLTF.preload('/skills/bedrock.glb');
