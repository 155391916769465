import { createContext, use, useContext, useEffect, useRef } from 'react';
import Lenis from 'lenis';

import { useGlobalStateStore } from '../globalState';

const LenisContext = createContext<{
  lenis?: Lenis;
}>({
  lenis: undefined,
});

const preventDefault = (event: Event) => {
  console.log('disable');
  return event.preventDefault();
};

const LenisWrapper = ({ children }: { children: React.ReactNode }) => {
  const lenisRef = useRef<Lenis>(undefined);
  const rafHandleRef = useRef<number | null>(null);
  const loading = useGlobalStateStore((state: any) => state.loading);

  useEffect(() => {
    // Initialize Lenis on the first render
    if (!lenisRef.current) {
      lenisRef.current = new Lenis({
        duration: 2,
        lerp: 0.1,
        syncTouch: true,
      });
      const raf = (time: number) => {
        lenisRef.current?.raf(time);
        rafHandleRef.current = requestAnimationFrame(raf);
      };
      rafHandleRef.current = requestAnimationFrame(raf);
    }

    // Clean up on component unmount
    return () => {
      if (lenisRef.current) {
        lenisRef.current.destroy();
        lenisRef.current = undefined;
      }
      if (rafHandleRef.current) {
        cancelAnimationFrame(rafHandleRef.current);
        rafHandleRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // Stop Lenis smooth scrolling
    lenisRef.current?.stop();

    // Block touch & wheel events
    window.addEventListener('wheel', preventDefault, { passive: false });
    window.addEventListener('touchmove', preventDefault, { passive: false });
    window.addEventListener('keydown', preventDefault, { passive: false });

    // Disable touch actions
    document.body.style.touchAction = 'none';

    if (!loading) {
      setTimeout(() => {
        // Restart Lenis scrolling
        lenisRef.current?.start();

        // Remove event listeners
        window.removeEventListener('wheel', preventDefault);
        window.removeEventListener('touchmove', preventDefault);
        window.removeEventListener('keydown', preventDefault);

        // Re-enable touch actions
        document.body.style.touchAction = '';
      }, 1000);
    }

    return () => {
      window.removeEventListener('wheel', preventDefault);
      window.removeEventListener('touchmove', preventDefault);
      window.removeEventListener('keydown', preventDefault);
      document.body.style.touchAction = '';
    };
  }, [loading, lenisRef]);

  return (
    <LenisContext.Provider value={{ lenis: lenisRef.current }}>{children}</LenisContext.Provider>
  );
};

export const useLenisContext = () => {
  const context = useContext(LenisContext);
  if (!context) {
    throw new Error('useLenisContext must be used within a LenisProvider');
  }
  return context;
};
export default LenisWrapper;
