'use client';

import { create } from 'zustand';

export const useGlobalStateStore = create((set) => ({
  currentSkill: 0,
  loading: true,
  setCurrentSkill: (currentSkill: number) => set({ currentSkill }),
  setLoading: (loading: boolean) => set({ loading }),
}));
