import { CardPayment } from './illustrators/CardPayment';
import { DynamoDB } from './illustrators/DynamoDB';
import { Lambda } from './illustrators/Lambda';

export default function BackendScene(props: any) {
  return (
    <group {...props}>
      <CardPayment
        position={[-4, 0, -2]}
        transition={{
          duration: 1,
        }}
        animate={{ rotateY: [-Math.PI * 2, -Math.PI / 6], x: [4, -4] }}
      />
      <Lambda
        position={[3, 1, 0]}
        scale={50}
        rotation={[0, 0, 0]}
        animate={{
          y: [0, 2],
          rotateX: [0, Math.PI / 2],
          rotateY: [0, 2 * Math.PI - Math.PI / 6],
          rotateZ: [0, Math.PI / 3],
        }}
        transition={{
          type: 'spring',
          dampling: 300,
          duration: 2,
        }}
      />
      <DynamoDB
        position={[2, 0, -2]}
        scale={50}
        rotation={[0, 0, 0]}
        animate={{
          y: [0, -1],
          rotateX: [0, Math.PI / 2],
          rotateY: [0, 2 * Math.PI - Math.PI / 6],
          rotateZ: [0, 2 * Math.PI],
        }}
        transition={{
          type: 'spring',
          dampling: 300,
          duration: 2,
        }}
      />
    </group>
  );
}
