/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 ./public/skills/dynamodb.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';
import { motion } from 'framer-motion-3d';

export function DynamoDB(props: any) {
  const { nodes, materials } = useGLTF('/skills/dynamodb.glb', true);
  return (
    <motion.group {...props} dispose={null}>
      <mesh
        geometry={(nodes.Rectangle as any).geometry}
        material={materials['Material.001']}
        position={[-0.011, 0, 0.01]}
        scale={[1, 2.588, 1]}
      />
      <mesh
        geometry={(nodes['Amazon-DynamoDB_Icon_64_Squid'] as any).geometry}
        material={materials['SVGMat.001']}
        position={[-0.01, 0, 0.01]}
      />
    </motion.group>
  );
}

useGLTF.preload('/skills/dynamodb.glb');
