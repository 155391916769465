'use client';

import { HTMLAttributes } from 'react';
import Link from 'next/link';

export default function Contact(props: HTMLAttributes<HTMLElement>) {
  const title = (
    <>
      <h2 className="text-4xl font-bold leading-tight lg:text-5xl">
        Let&apos;s talk about everything!
      </h2>
      <div className="mt-3 text-gray-700">
        Hate forms? Send me an{' '}
        <Link
          className="font-bold underline"
          href={'mailto:huubinh0409@gmail.com'}
          aria-label="The author\'s email"
        >
          email
        </Link>{' '}
        instead.
      </div>
    </>
  );
  return (
    <section
      className={'min-h-[300px] w-full overflow-hidden bg-gray-200 dark:bg-gray-200'}
      {...props}
    >
      <div className={`container gap-8 rounded-sm py-12 text-gray-900`}>
        <div className="flex basis-1/2 flex-col gap-8 sm:flex-row">
          <div className="block">{title}</div>
        </div>
      </div>
    </section>
  );
}
