import Bedrock from './illustrators/Bedrock';

export default function AIScene(props: any) {
  return (
    <>
      <Bedrock
        position={[3, 0, -2]}
        scale={40}
        animate={{
          y: [2, 0],
          rotateX: [0, -1.5 * Math.PI],
          rotateY: [0, -2 * Math.PI],
        }}
        transition={{
          type: 'spring',
          duration: 2,
        }}
      />
    </>
  );
}
