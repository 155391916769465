'use client';

import { glassMorphismClass } from '@/config/constants';
import { useGlobalStateStore } from '@/providers/globalState';
import { cubicBezier, motion, useScroll, useTransform } from 'framer-motion';

import { ThemeToggle } from '../../providers/theme/theme-toggle';
import Logo from '../logos/Logo';
import css from './Header.module.css';

const Header = () => {
  const { scrollYProgress } = useScroll();
  const width = useTransform(scrollYProgress, [0, 1], ['100%', '0%'], {
    ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
  });
  const loading = useGlobalStateStore((state: any) => state.loading);

  return (
    <header
      className={
        'fixed left-0 top-0 flex h-fit w-full flex-col flex-nowrap items-center justify-center' +
        glassMorphismClass +
        (loading ? ' z-0' : ' z-[100]')
      }
      id="header"
    >
      <div className="h-1 w-full bg-[linear-gradient(to_right,theme(colors.indigo.400),theme(colors.indigo.100),theme(colors.sky.400),theme(colors.fuchsia.400),theme(colors.sky.400),theme(colors.indigo.100),theme(colors.indigo.400))]">
        <motion.div
          initial={{
            width: '100%',
          }}
          style={{
            width,
          }}
          className="float-right h-full bg-primary will-change-[width]"
        />
      </div>
      <nav
        className={
          'flex h-fit w-full justify-center' + ' items-center' + ' container bg-transparent py-1.5'
        }
      >
        <ul className={'flex size-full list-none flex-nowrap items-center justify-between'}>
          <li className={'cursor-pointer'}>
            <a
              aria-label="Scroll to the introduction section"
              href="#hero"
              className={
                'decoration-none h-max w-max text-lg font-bold hover:cursor-pointer' +
                ' hover:text-primary-color flex items-center justify-center p-1 align-baseline' +
                glassMorphismClass
              }
            >
              <Logo
                className={
                  'h-[32px] w-[32px] cursor-pointer md:h-[48px] md:w-[48px]' +
                  ' [&>g]:stroke-primary'
                }
              />
            </a>
          </li>
          <li className={'flex cursor-pointer gap-2 md:gap-4'}>
            <a
              className={'relative px-4 py-2 text-xs md:text-lg ' + css.bestiaButton}
              href={
                'https://binh-portfolio-website.s3.ap-southeast-1.amazonaws.com/Binh_Nguyen_SE.pdf'
              }
              rel="noreferrer"
              target={'_blank'}
            >
              <div
                className={
                  'absolute bg-primary-content before:bg-black after:bg-black ' + css.bestiaBg
                }
              />
              <span className={'text-primary'}>DOWNLOAD CV</span>
            </a>
            <ThemeToggle />
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
